import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
// import env from '@/config/env';
let AppImage = class AppImage extends Vue {
    get src() {
        if (!this.url)
            return require('@/assets/app-icon/local-app-icon-1.png');
        if (this.url.includes('local-app-icon')) {
            return require(`@/assets/app-icon/${this.url}`);
        }
        return this.url;
    }
    bColor(colorClass) {
        switch (colorClass) {
            case 'color-1': return 'linear-gradient(130deg, #657EFF 0%, #72A2FF 100%)';
            case 'color-2': return 'linear-gradient(130deg, #2DAAEA 0%, #3BD0EC 100%)';
            case 'color-3': return 'linear-gradient(130deg, #2BB69C 0%, #34D6B8 100%)';
            case 'color-4': return 'linear-gradient(130deg, #657EFF 0%, #FFA916 100%)';
            case 'color-5': return 'linear-gradient(130deg, #F49222 0%, #FF7676 100%)';
            case 'color-6': return 'linear-gradient(130deg, #F95465 0%, #D86EFF 100%)';
            case 'color-7': return 'linear-gradient(130deg, #657EFF 0%, #909EB8 100%)';
        }
    }
};
__decorate([
    Prop()
], AppImage.prototype, "url", void 0);
__decorate([
    Prop()
], AppImage.prototype, "appCode", void 0);
AppImage = __decorate([
    Component({
        name: 'appImage',
    })
], AppImage);
export default AppImage;
